<template>
  <div>
    <QuotaItem
      v-for="(quotaItem, quotaItemIndex) in listOfQuotas"
      :key="quotaItemIndex"
      :quota="quotaItem"
      :index="quotaItemIndex"
      @deleteQuota="$emit('deleteQuota')"
    />
    <AddQuota
      v-if="displayAddQuota"
      :quotasQuantity="
        listOfQuotas.filter((quota) => quota.status === true).length
      "
    />
  </div>
</template>

<script>
export default {
  name: "ListOfQuotas",
  props: {
    listOfQuotas: {
      type: Array,
    },
  },
  components: {
    AddQuota: () => import("../AddQuota/AddQuota.vue"),
    QuotaItem: () => import("../QuotaItem/QuotaItem.vue"),
  },
  computed: {
    displayAddQuota() {
      return this.listOfQuotas.length <= 1;
    },
  },
};
</script>

<style src="./ListOfQuotas.scss" lang="scss" scoped></style>